import { TippedInfoDetail } from 'common/interfaces/api';
import LabelButton from 'components/common/LabelButton';
import TippedItem from 'components/common/TippedItem';
import styles from './TippedListOfPost.module.scss';
import { useRef } from 'react';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDevice } from 'common/utils';

const TippedListOfPost = ({
  tippedList = [],
  tippedCount = 0,
  setOpenTippedList,
}: {
  tippedList: TippedInfoDetail[];
  tippedCount: number;
  setOpenTippedList?: (open: boolean) => void;
}): JSX.Element => {
  const listRef = useRef<HTMLDivElement>(null);
  const [maxItemsCount, setMaxItemsCount] = useState(5);
  const deviceInfo = useDevice();
  useEffect(() => {
    if (listRef.current?.clientWidth) {
      const itemWidth = deviceInfo.isIos ? 95 : 86;
      setMaxItemsCount(Math.floor(listRef.current.clientWidth / itemWidth - 1));
    }
  }, [listRef.current?.clientWidth, deviceInfo]);

  const needShowMore = tippedCount > maxItemsCount;
  return (
    <div className={styles.tippedListWrapper} ref={listRef}>
      {tippedList.length > 0 ? (
        <div className={styles.tippedList}>
          {tippedList.slice(0, maxItemsCount).map((tippedInfo, index) => {
            return (
              <TippedItem
                className={styles.tippedLabel}
                tippedInfo={tippedInfo}
                key={`tip-${index}`}
              />
            );
          })}
          {needShowMore && (
            <LabelButton
              text={
                <div className={styles.btnText}>
                  +{tippedCount - maxItemsCount}
                </div>
              }
              className={classNames(styles.tippedLabel, styles.loadmoreBtn)}
              type="gray"
              onClick={() => setOpenTippedList && setOpenTippedList(true)}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TippedListOfPost;
