import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import cn from 'classnames';
import axios from 'axios';
import { useMutation } from 'react-query';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ProgressRing from 'components/common/ProgressRing';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import Button from 'components/common/Button';
import TextEditor, { TextEditorRef } from 'components/common/TextEditor';
import { useMediaQuery } from 'common/utils';

import styles from './ReportBox.module.scss';

export type ReportBoxProps = {
  postId?: number;
  // onDialogSubmit is needed isDialog === true
  onDialogSubmit?: () => void;
  // targetUserId is needed to report user by id
  targetUserId?: number;
};
export default function ReportBox({
  postId,
  onDialogSubmit,
  targetUserId,
}: ReportBoxProps): JSX.Element {
  const charLimit = 300;
  const selfRef = useRef<HTMLDivElement>(null);
  const currentUser = useStore((state) => state.currentUser);
  const showLogin = useGlobalStore((state) => state.showLogin);
  const [postable, setPostable] = useState(false);
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const [isFocused, setFocused] = useState(false);
  const [plainText, setPlainText] = useState('');
  const textLength = useMemo(() => plainText.length, [plainText]);
  const textEditorRef = useRef<TextEditorRef>();
  const isMobile = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    setPostable(textLength > 0 && textLength <= charLimit);
  }, [textLength, charLimit]);

  const isInlineMode = !isFocused && textLength === 0;

  const { isLoading: isMakingReport, mutate: makeReport } = useMutation(
    async () => {
      const content = textEditorRef.current.getContent();
      if (targetUserId) {
        await axios.post(`/users/reports`, {
          user_id: targetUserId,
          action: 'report',
          reason: content.trim(),
        });
      } else {
        await axios.post(`/posts/reports`, {
          post_id: postId,
          action: 'report',
          reason: content.trim(),
        });
      }
    },
    {
      onSuccess: () => {
        textEditorRef.current.reset();
        setSnackbarMessage({ type: 'success', text: 'レポートしました。' });
        if (targetUserId) {
          onDialogSubmit();
        } else {
          onDialogSubmit();
        }
      },
      onError: () => {
        setSnackbarMessage({ type: 'error', text: 'レポートが失敗しました' });
      },
    }
  );

  const handleMakeReport = () => {
    if (!currentUser) {
      showLogin({});
    } else {
      makeReport();
    }
  };

  const handleTextAreaFocus = useCallback(() => setFocused(true), []);

  const handleClickAway = useCallback(() => setFocused(false), []);

  return (
    <>
      <div className={styles.reportBox} ref={selfRef}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div
            className={cn(styles.inputBox, {
              [styles.expanded]: !isInlineMode,
            })}
          >
            <div className={styles.textInputWrapper}>
              <div className={styles.textInput}>
                <TextEditor
                  ref={textEditorRef}
                  setPlainText={setPlainText}
                  placeholder="理由を教えてください。"
                  onFocus={handleTextAreaFocus}
                  charLimit={charLimit}
                />
              </div>
            </div>
            <div className={styles.footer}>
              <div
                className={cn(styles.rightSide, {
                  [styles.expanded]: !isInlineMode,
                })}
              >
                <div className={styles.progress}>
                  <ProgressRing
                    radius={22}
                    current={textLength}
                    total={charLimit}
                  />
                </div>
                <Button
                  disabled={!postable}
                  onClick={handleMakeReport}
                  text="送信"
                  rounded
                  verticalPadding={isMobile ? 5 : 9}
                  isLoading={isMakingReport}
                />
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </>
  );
}
