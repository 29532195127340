import React, {
  useState,
  useEffect,
  useRef,
  MouseEventHandler,
  useCallback,
} from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';

import {
  Post,
  Language,
  MediaType,
  PostType,
  PostState,
  PostParentKey,
} from 'common/interfaces/api';
import Icon from 'components/common/Icon';
import { postItemText, timeSince } from 'common/utils';
import NewPostReActions from 'components/common/NewPostReActions';
import UserInfo from 'components/common/UserInfo';
import ThreeDotsMenu from './ThreeDotsMenu';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import RichContent from 'components/common/RichContent';
import { useMediaQuery } from 'common/utils';

import styles from './Post.module.scss';
import LitePost from 'components/LitePost';
import MediaContent from 'components/MediaContent';
import { getPostDetail } from 'service/post';
import TippedListOfPost from 'components/PostDetails/TippedListOfPost';
import dayjs from 'lib/dayjs';
import PaidLabel from 'components/common/PaidLabel';
import Link from 'next/link';
import { parentKeyToScreenName, ScreenName } from 'common/utils/pp_tracking';
import {
  addClickPostEvent,
  addImpressionPostEvent,
  checkIsContentLocked,
  checkIsPaidPost,
} from 'common/utils/post';

export type PostProps = {
  post: Post;
  parent: PostParentKey | string;
  shouldAnimate?: boolean;
  htmlContent?: boolean;
  hasFollowBtn?: boolean;
  isPinned?: boolean;
};

function PostItem({
  post,
  parent,
  shouldAnimate = false,
  htmlContent = false,
  hasFollowBtn = false,
  isPinned = false,
}: PostProps): JSX.Element {
  // Local post state so that edited content is shown immediately
  const [localPost, setLocalPost] = useState(post);
  const [shouldAnimateExit, setShouldAnimateExit] = useState(false);
  const currentUser = useStore((state) => state.currentUser);
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const showCreatePostDialog = useGlobalStore(
    (state) => state.showCreatePostDialog
  );
  const [hiding, setHiding] = useState(false);
  const showCreatePostDialogMobile = useGlobalStore(
    (state) => state.showCreatePostDialogMobile
  );
  const [canSeeFreeMinutes, setCanSeeFreeMinutes] = useState(false);
  const isProfilePage = parent === PostParentKey.ProfilePosts;
  const postDetailsUrl = `/${localPost.user_info?.username}/posts/${localPost.id}`;
  const [sharedPost, setSharedPost] = useState<Post>(null);

  const isOwnPost = localPost.is_my_post;

  const isContentLocked = checkIsContentLocked(localPost);

  useEffect(() => {
    setLocalPost(post);
  }, [post]);

  useEffect(() => {
    if (
      !localPost.media ||
      !localPost.media[0] ||
      localPost.media[0].type !== MediaType.Link
    ) {
      setSharedPost(null);
      return;
    }
  }, [localPost]);

  // Text content
  const postTextRef = useRef(null);
  const content = (
    <div className={styles.postTextWrapper}>
      <div
        className={cn(styles.postText, {
          [styles.noMedia]: !(localPost.media && localPost.media[0]),
        })}
      >
        <span className={styles.text} ref={postTextRef}>
          {htmlContent ? (
            <div
              dangerouslySetInnerHTML={{
                __html: localPost.text,
              }}
              style={{ whiteSpace: 'pre-line' }}
              className={styles.richcontent}
            />
          ) : (
            <RichContent text={postItemText(localPost.text, 2)} />
          )}
        </span>
        <div className={styles.readmoreWrapper}>
          <Link href={postDetailsUrl}>
            <a className={styles.readmore}>続きを読む</a>
          </Link>
        </div>
      </div>
    </div>
  );

  const intersectionCb: IntersectionObserverCallback = useCallback(
    (entries) => {
      const target = entries[0];
      if (target && target.isIntersecting) {
        addImpressionPostEvent({
          postId: post.id,
          screen: parentKeyToScreenName(parent) as ScreenName,
          postSource: post.source,
        });
        // CENTER: entering the middle 10% of screen height
      }
    },
    [parent, post.id, post.source]
  );
  useEffect(() => {
    const ref = postRef.current;
    const options = {
      root: null,
      rootMargin: '-45% 0px -45%',
      threshold: 0,
    };
    // Create observer
    const observer = new IntersectionObserver(intersectionCb, options);
    // observe the audio player
    if (ref) {
      observer.observe(ref);
    }
    // clean up on willUnMount
    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [intersectionCb]);

  // Right side components
  const isScheduledPost = new Date(localPost.created_at) > new Date();
  const timeSinceText = isScheduledPost
    ? `${dayjs(localPost.created_at).format('YYYY/MM/DD HH:mm')}に公開予定`
    : timeSince(new Date(localPost.created_at), Language.Ja);

  // Handle clicking on post
  const router = useRouter();
  const userInfoRef = useRef<HTMLDivElement>();
  const postActionsRef = useRef<HTMLDivElement>();
  const rightSideRef = useRef<HTMLDivElement>();
  const postRef = useRef<HTMLDivElement>();
  const sharePostRef = useRef<HTMLDivElement>();
  const handlePostClick: MouseEventHandler = (e) => {
    const selection = window.getSelection();
    if (
      selection.type != 'Range' &&
      !userInfoRef?.current?.contains(e.target as HTMLElement) &&
      !postActionsRef.current.contains(e.target as HTMLElement) &&
      !rightSideRef.current.contains(e.target as HTMLElement) &&
      !sharePostRef?.current?.contains(e.target as HTMLElement) &&
      // prevent portal from handling click event
      postRef.current.contains(e.target as HTMLElement)
    ) {
      addClickPostEvent({
        postId: post.id,
        screen: parentKeyToScreenName(parent) as ScreenName,
        postSource: post.source,
        clickPosition: 'post_view',
        postType: post.type,
        mediaType: post.media?.[0]?.type,
      });
      void router.push(postDetailsUrl);
    }
  };

  const menuRender = (
    <div className={styles.rightSide} ref={rightSideRef}>
      {checkIsPaidPost(localPost.type) && (
        <div className={styles.paidLabel}>
          <PaidLabel
            size={28}
            freeMinutesFontSize={9}
            freeMinutes={localPost.free_minutes || 0}
            isGoldPost={localPost.type === PostType.Gold}
            isPlusPost={localPost.type === PostType.Plus}
          />
        </div>
      )}

      {!isMobileScreen && !localPost.is_affiliate && (
        <div className={styles.timeSince}>{timeSinceText}</div>
      )}
      <div>
        {currentUser && (
          <ThreeDotsMenu
            isOwnPost={isOwnPost}
            postId={localPost.id}
            isPinned={isPinned}
            isProfilePage={isProfilePage}
            isSaved={localPost.is_saved}
            isStreamingPost={!!localPost.streaming}
            onHide={() => setHiding(true)}
            isGoldPost={localPost.type === PostType.Gold}
            userId={localPost.user_info?.id}
            onDelete={() => setShouldAnimateExit(true)}
            isProcessing={localPost.status === PostState.OnProcessing}
            onEdit={async () => {
              try {
                const postData = (await getPostDetail(post.id, true))?.data;
                if (isMobileScreen) {
                  showCreatePostDialogMobile({
                    post: postData || localPost,
                    setLocalPost: setLocalPost,
                    editMode: true,
                    open: true,
                  });
                } else {
                  showCreatePostDialog({
                    post: postData || localPost,
                    setLocalPost: setLocalPost,
                    editMode: true,
                    open: true,
                  });
                }
                // eslint-disable-next-line no-empty
              } catch (error) {}
            }}
            isAdsPost={localPost.is_affiliate}
          />
        )}
      </div>
    </div>
  );

  if (hiding) {
    return (
      <motion.div
        initial={shouldAnimate ? { opacity: 0, y: -150 } : false}
        animate={{ opacity: 1, y: 0 }}
        exit={shouldAnimateExit ? { opacity: 0, x: '70%' } : {}}
        ref={postRef}
      ></motion.div>
    );
  }
  return (
    <motion.div
      initial={shouldAnimate ? { opacity: 0, y: -150 } : false}
      animate={{ opacity: 1, y: 0 }}
      exit={shouldAnimateExit ? { opacity: 0, x: '70%' } : {}}
      className={cn(styles.postWrapper, {
        [styles.pinned]: !localPost.is_onboarding_free_paid_post && isPinned,
        [styles.freePaidPost]: !!localPost.is_onboarding_free_paid_post,
      })}
      ref={postRef}
    >
      {localPost.is_onboarding_free_paid_post ? (
        <div className={styles.pinLabel}>
          <Icon name="free" width={24} height={24} />
          <span>無料閲覧可能枠</span>
        </div>
      ) : (
        isPinned && (
          <div className={styles.pinLabel}>
            <Icon name="pin-on" width={24} height={24} />
            <span>固定された投稿</span>
          </div>
        )
      )}
      <MediaContent
        localPost={localPost}
        sharedPost={sharedPost}
        setSharedPost={setSharedPost}
        isContentLocked={isContentLocked}
        parent={`${parent}${isPinned ? '-is_pinned' : ''}`}
        isMobileScreen={isMobileScreen}
        canSeeFreeMinutes={canSeeFreeMinutes}
        setCanSeeFreeMinutes={setCanSeeFreeMinutes}
      />
      <div
        className={cn(styles.post, {
          [styles.hasMedia]: localPost.media && localPost.media[0],
        })}
        onClick={handlePostClick}
      >
        <div className={styles.header}>
          <div ref={userInfoRef}>
            <UserInfo
              user={localPost.user_info}
              hasFollowBtn={!isOwnPost && hasFollowBtn}
              badgeSize={40}
              postedAt={isMobileScreen ? timeSinceText : ''}
              isAdsPost={localPost.is_affiliate}
              showLiveFlag
            />
          </div>
          {menuRender}
        </div>
        {content}
        {/* show symbol */}
        {localPost.symbols && localPost.symbols.length > 0 && (
          <div className={styles.symbols}>
            {(localPost.symbols || []).map((symbol, index) => {
              return (
                <div className={styles.symbol} key={`symbol-${index}`}>
                  <span>{symbol.name}</span>
                </div>
              );
            })}
          </div>
        )}
        {/* show tipping list */}
        <div className={styles.tippedList}>
          {post.user_tipping && (
            <TippedListOfPost
              tippedList={post.user_tipping}
              tippedCount={post.user_tipping.length}
            />
          )}
        </div>

        {sharedPost && (
          <div className={styles.sharedPost} ref={sharePostRef}>
            <LitePost post={sharedPost} parent={parent} />
          </div>
        )}

        <div className={styles.footer} ref={postActionsRef}>
          <NewPostReActions
            post={localPost}
            handleClickComment={() => {
              addClickPostEvent({
                postId: post.id,
                screen: parentKeyToScreenName(parent) as ScreenName,
                postSource: post.source,
                clickPosition: 'comment_button',
                postType: post.type,
                mediaType: post.media?.[0]?.type,
              });
              void router.push(postDetailsUrl);
            }}
            showViewCount={isProfilePage && isOwnPost}
            showNumber={isProfilePage && isOwnPost}
            screen={parentKeyToScreenName(parent) as ScreenName}
          />
        </div>
      </div>
    </motion.div>
  );
}

const MemoizedPostItem = React.memo(PostItem);
export default MemoizedPostItem;
