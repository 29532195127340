import React, { useRef, MouseEventHandler, useState } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Post, Language, PostParentKey } from 'common/interfaces/api';
// import Icon from 'components/common/Icon';
import UserInfo from 'components/common/UserInfo';
import RichContent from 'components/common/RichContent';
import { postItemText, timeSince, useMediaQuery } from 'common/utils';

import styles from './Post.module.scss';
import MediaContent from 'components/MediaContent';
import { checkIsContentLocked } from 'common/utils/post';

export type PostProps = {
  post: Post;
  parent: PostParentKey | string;
  hasFollowBtn?: boolean;
  showReadMore?: boolean;
};

function LitePost({
  post,
  parent,
  hasFollowBtn = false,
  showReadMore = false,
}: PostProps): JSX.Element {
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const postDetailsUrl = `/${post.user_info.username}/posts/${post.id}`;
  const [canSeeFreeMinutes, setCanSeeFreeMinutes] = useState(false);

  const isOwnPost = post.is_my_post;

  const isContentLocked = checkIsContentLocked(post);

  // Text content
  const content = (
    <div className={styles.postTextWrapper}>
      <div className={styles.postText}>
        <span className={styles.text}>
          <RichContent text={postItemText(post.text, 2)} />
        </span>
      </div>
    </div>
  );

  // Right side components
  const isScheduledPost = new Date(post.created_at) > new Date();
  const timeSinceText = isScheduledPost
    ? `${new Date(post.created_at).toLocaleString()}に公開予定`
    : timeSince(new Date(post.created_at), Language.Ja);

  // Handle clicking on post
  const router = useRouter();
  const userInfoRef = useRef<HTMLDivElement>();
  const rightSideRef = useRef<HTMLDivElement>();
  const postRef = useRef<HTMLDivElement>();
  const handlePostClick: MouseEventHandler = (e) => {
    const selection = window.getSelection();
    if (
      selection.type != 'Range' &&
      !userInfoRef?.current?.contains(e.target as HTMLElement) &&
      !rightSideRef.current.contains(e.target as HTMLElement) &&
      // prevent portal from handling click event
      postRef.current.contains(e.target as HTMLElement)
    ) {
      void router.push(postDetailsUrl);
    }
  };

  const menuRender = (
    <div className={styles.rightSide} ref={rightSideRef}>
      {!isMobileScreen && !post.is_affiliate && (
        <div className={styles.timeSince}>{timeSinceText}</div>
      )}
    </div>
  );

  return (
    <div className={cn(styles.postWrapper)} ref={postRef}>
      <MediaContent
        localPost={post}
        parent={parent}
        isContentLocked={isContentLocked}
        isMobileScreen={isMobileScreen}
        isLitePost
        canSeeFreeMinutes={canSeeFreeMinutes}
        setCanSeeFreeMinutes={setCanSeeFreeMinutes}
      />

      <div className={styles.post} onClick={handlePostClick}>
        <div className={styles.header}>
          <div ref={userInfoRef}>
            <UserInfo
              user={post.user_info}
              hasFollowBtn={!isOwnPost && hasFollowBtn}
              badgeSize={40}
              postedAt={isMobileScreen ? timeSinceText : ''}
              isAdsPost={post.is_affiliate}
              showLiveFlag
            />
          </div>
          {menuRender}
        </div>
        {content}
        <div className={styles.footer}>
          {showReadMore && (
            <div className={styles.readMore}>
              <Link href={postDetailsUrl}>
                <a>続きを読む</a>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const MemoizedPostItem = React.memo(LitePost);
export default MemoizedPostItem;
