import axios from 'axios';
import React, { useState, useRef } from 'react';
import ConfirmDialog from 'components/common/ConfirmDialog';
import ReportBox from 'components/common/ReportBox';
import Icon from 'components/common/Icon';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';

import { useOutsideClick } from 'common/utils';
import { GetPostsStatusResult, PostState } from 'common/interfaces/api';
import styles from './ThreeDotsMenu.module.scss';
import classNames from 'classnames';
import { useEffect } from 'react';

export type Props = {
  postId: number;
  userId: number;
  isOwnPost?: boolean;
  isAdsPost?: boolean;
  isStreamingPost?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  onBlock?: () => void;
  onHide?: () => void;
  isProcessing?: boolean;
  isPinned?: boolean;
  isProfilePage?: boolean;
  isSaved?: boolean;
  isGoldPost: boolean;
  isPostDetail?: boolean;
};

export default function ThreeDotsMenu({
  postId,
  userId,
  isAdsPost = false,
  isOwnPost = false,
  isStreamingPost = false,
  onDelete,
  onEdit,
  onBlock,
  onHide,
  isProcessing = false,
  isPinned = false,
  isProfilePage = false,
  isSaved,
  isGoldPost = false,
  isPostDetail = false,
}: Props): JSX.Element {
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const [saved, setSaved] = useState(!!isSaved);
  const [show, setShow] = useState(false);
  const threeDotRef = useRef();
  useOutsideClick(threeDotRef, () => {
    if (show) setShow(false);
  });

  useEffect(() => {
    setSaved(isSaved);
  }, [isSaved]);

  const refetchPosts = useStore((state) => state.refetchPosts);
  const [openConfirmDeletePost, setOpenConfirmDeletePost] = useState(false);
  const [openConfirmReportPost, setOpenConfirmReportPost] = useState(false);
  const [openConfirmBlockUser, setOpenConfirmBlockUser] = useState(false);
  const [openConfirmReportUser, setOpenConfirmReportUser] = useState(false);
  const handleDeletePostClick = () => {
    setOpenConfirmDeletePost(true);
  };

  const handleBlockUser = async () => {
    try {
      await axios.post(`/users/blocks`, {
        user_id: userId,
        action: 'block',
      });
      setSnackbarMessage({
        text: 'ユーザーがブロックされました',
        type: 'success',
      });
      setOpenConfirmBlockUser(false);
      if (onBlock) onBlock();
    } catch (e) {
      setSnackbarMessage({
        type: 'error',
        text: 'ユーザーのブロックが失敗しました',
      });
    }
    setShow(false);
  };

  const handleDeletePost = async () => {
    try {
      await axios.delete(`/posts/${postId}`);
      setSnackbarMessage({ text: '投稿が削除されました', type: 'success' });
      if (onDelete) onDelete();
      setOpenConfirmDeletePost(false);
      setTimeout(() => {
        refetchPosts();
      }, 200);
    } catch (e) {
      setSnackbarMessage({
        type: 'error',
        text: '投稿の削除が失敗しました',
      });
    }
    setShow(false);
  };

  const handleHidePostClick = async () => {
    try {
      await axios.post(`/posts/${postId}/hides`, {});
      setSnackbarMessage({
        type: 'success',
        text: 'タイムラインから非表示しました。',
      });
      if (onHide) onHide();
    } catch (e) {
      setSnackbarMessage({
        type: 'error',
        text: '投稿の非表示が失敗しました',
      });
    }
    setShow(false);
  };

  const handleSavePostClick = async (action: 'save' | 'unsave') => {
    try {
      await axios.post(`/posts/saves`, {
        post_id: postId,
        action: action,
      });
      setSaved(action === 'save' ? true : false);
    } catch (e) {
      setSnackbarMessage({ text: 'エラーが発生しました', type: 'error' });
    }
  };

  const handlePinPostClick = async () => {
    try {
      await axios.post(
        `/profiles/${isPinned ? 'unpin_post' : 'pin_post'}/${postId}`,
        {}
      );
      setSnackbarMessage({
        type: 'success',
        text: `${
          isPinned
            ? 'プロフィールの固定を外しました'
            : 'プロフィールに固定しました'
        }`,
      });
      setTimeout(() => {
        if (isProfilePage) refetchPosts();
      }, 200);
    } catch (e) {
      setSnackbarMessage({
        type: 'error',
        text: 'エラーが発生しました',
      });
    }
    setShow(false);
  };

  const content: JSX.Element = isOwnPost ? (
    <ul className={styles.menuWrapper}>
      <li onClick={() => handleSavePostClick(saved ? 'unsave' : 'save')}>
        <Icon name="save-1" isOn={saved} hasOn width={24} height={24} />
        {saved ? (
          <span>保存した投稿から外す</span>
        ) : (
          <span>投稿を保存する</span>
        )}
      </li>
      {!isStreamingPost && (
        <li
          onClick={async () => {
            if (isProcessing) {
              const postStatus = (
                await axios.get(`/posts/status?ids=${postId}`)
              ).data as GetPostsStatusResult;
              if (
                postStatus?.data &&
                postStatus?.data[0]?.status !== PostState.OnProcessing
              ) {
                onEdit();
              } else {
                setSnackbarMessage({
                  type: 'warning',
                  text: '処理中のため、少しお待ちください',
                });
              }

              setShow(false);
              return;
            }
            onEdit();
          }}
          className={classNames({
            [styles.hideOption]: isAdsPost || isGoldPost,
          })}
        >
          <Icon name="edit-black" width={20} height={20} />
          <span>投稿を編集する</span>
        </li>
      )}
      {(isProfilePage || isPostDetail) && (
        <li onClick={handlePinPostClick}>
          <Icon name="pin" width={20} height={20} />
          <span>
            {isPinned ? 'プロフィールの固定を外す' : 'プロフィールに固定する'}
          </span>
        </li>
      )}
      {(isProfilePage || isPostDetail) && (
        <li onClick={handleDeletePostClick} style={{ color: '#FA553F' }}>
          <Icon name="trash-red" width={20} height={20} />
          <span>投稿を削除する</span>
        </li>
      )}
    </ul>
  ) : (
    <ul className={styles.menuWrapper}>
      <li onClick={() => handleSavePostClick(saved ? 'unsave' : 'save')}>
        <Icon name="save-1" isOn={saved} hasOn width={24} height={24} />
        {saved ? (
          <span>保存した投稿から外す</span>
        ) : (
          <span>投稿を保存する</span>
        )}
      </li>
      <li onClick={() => setOpenConfirmReportPost(true)}>
        <Icon name="post-report" width={24} height={24} />
        <span>投稿問題をレポートする</span>
      </li>
      {!isAdsPost && (
        <li onClick={() => setOpenConfirmBlockUser(true)}>
          <Icon name="user-off" width={24} height={24} />
          <span>ユーザーをブロックする</span>
        </li>
      )}
      <li onClick={() => setOpenConfirmReportUser(true)}>
        <Icon name="user-report" width={24} height={24} />
        <span>ユーザーをレポートする</span>
      </li>
      {!isAdsPost && (
        <li onClick={handleHidePostClick}>
          <Icon name="post-off" width={24} height={24} />
          <span>投稿を非表示にする</span>
        </li>
      )}
    </ul>
  );

  return (
    <div className={styles.threeDotsMenuWrapper} ref={threeDotRef}>
      <div className={styles.triggerWrapper} onClick={() => setShow(!show)}>
        <Icon name="dropdown" width={24} height={24} />
      </div>
      <div
        className={styles.contentWrapper}
        style={{
          display: show ? 'block' : 'none',
        }}
      >
        <div className={styles.arrow}></div>
        {content}
      </div>
      {openConfirmDeletePost && (
        <ConfirmDialog
          open={openConfirmDeletePost}
          actionCloseFnc={() => setOpenConfirmDeletePost(false)}
          actionSelectedFnc={handleDeletePost}
          title="本当に削除しますか？"
        />
      )}
      {openConfirmBlockUser && (
        <ConfirmDialog
          open={openConfirmBlockUser}
          actionCloseFnc={() => setOpenConfirmBlockUser(false)}
          actionSelectedFnc={handleBlockUser}
          title="本当にブロックしますか？"
          yesText="ブロックする"
        />
      )}
      {openConfirmReportPost && (
        <ConfirmDialog
          open={openConfirmReportPost}
          actionCloseFnc={() => setOpenConfirmReportPost(false)}
          title="オプション選択"
          withTextBox
          textBox={
            <ul className={styles.menuWrapper}>
              <li>
                <a
                  href="https://help.postprime.com/hc/ja/requests/new?ticket_form_id=360008586356"
                  target="_blank"
                  rel="noopener"
                >
                  著作権侵害の報告
                </a>
              </li>
              <li>
                <a
                  href="https://help.postprime.com/hc/ja/requests/new?ticket_form_id=360007972215"
                  target="_blank"
                  rel="noopener"
                >
                  個人情報に関する苦情
                </a>
              </li>
              <li>
                <a
                  href="https://help.postprime.com/hc/ja/requests/new?ticket_form_id=360008586376"
                  target="_blank"
                  rel="noopener"
                >
                  その他の違反報告
                </a>
              </li>
              <li
                style={{ marginTop: '20px' }}
                onClick={() => setOpenConfirmReportPost(false)}
              >
                キャンセル
              </li>
            </ul>
          }
        />
      )}
      {openConfirmReportUser && (
        <ConfirmDialog
          open={openConfirmReportUser}
          actionCloseFnc={() => setOpenConfirmReportUser(false)}
          title="ユーザーをレポートする"
          withTextBox
          textBox={
            <ReportBox
              targetUserId={userId}
              onDialogSubmit={() => setOpenConfirmReportUser(false)}
            />
          }
        />
      )}
    </div>
  );
}
