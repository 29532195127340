import styles from './PaidLabel.module.scss';
import Icon from 'components/common/Icon';
import { FREE_MINUTES_OPTIONS } from 'common/utils';
export default function PaidLabel({
  freeMinutes = 0,
  size = 20,
  freeMinutesFontSize = 7,
  isFree = false,
  isGoldPost = false,
  isPlusPost = false,
}: {
  freeMinutes?: number;
  size: number;
  freeMinutesFontSize?: number;
  isFree?: boolean;
  isGoldPost?: boolean;
  isPlusPost?: boolean;
}): JSX.Element {
  if (isPlusPost) {
    return (
      <div
        className={styles.paidLabel}
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <Icon name="prime-plus-label" width={size} height={size} />
      </div>
    );
  }
  if (isGoldPost) {
    return (
      <div
        className={styles.paidLabel}
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <Icon name="gold-post-icon" width={size} height={size} />
      </div>
    );
  }
  if (isFree) {
    return (
      <div
        className={styles.paidLabel}
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <Icon name="free-label-v1" width={size} height={size} />
      </div>
    );
  }
  if (!isFree && freeMinutes === 0)
    return (
      <div
        className={styles.paidLabel}
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <Icon name="paid-label" width={size} height={size} />
      </div>
    );
  if (!isFree && FREE_MINUTES_OPTIONS.includes(freeMinutes))
    return (
      <div
        className={styles.paidLabel}
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <Icon name={`free-${freeMinutes}-min`} width={size} height={size} />
      </div>
    );
  if (freeMinutes === 0) return <></>;
  return (
    <div
      className={styles.paidLabel}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <div className={styles.background}>
        <img src="/images/paid-label-bg.png" width={size} height={size} />
      </div>
      <span
        className={styles.freeMinutes}
        style={{
          fontSize: `${freeMinutesFontSize}px`,
          minWidth: `${size}px`,
          height: `${size}px`,
        }}
      >
        {isFree ? '無料' : `${freeMinutes}分`}
      </span>
    </div>
  );
}
